import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const googleReviews = document.querySelector('#google-reviews');
if (googleReviews) {
  const pagination = document.querySelector('.google-reviews-slider__btns .swiper-pagination');
  const googleReviewsSlider = new Swiper(".google-reviews-slider", {
    modules: [Autoplay, Navigation, Pagination],
    slidesPerView: 3,
    spaceBetween: 40,
    autoplay: {
      delay: 4000,
      pauseOnMouseEnter: window.innerWidth >= 1025,
      disableOnInteraction: false,
    },
    pagination: {
      el: pagination,
      clickable: true,
    },
    /*navigation: {
      prevEl: ".google-reviews-slider__prev",
      nextEl: ".google-reviews-slider__next",
    },*/
    breakpoints: {
      1600: {
        spaceBetween: 40,
      },
      1400: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
      1100: {
        spaceBetween: 20,
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      769: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      300: {
        slidesPerView: 1,
        spaceBetween: 20,
      }
    },
  });

  function textMore() {
    const googleReviewsSwiper = document.querySelector(
        ".google-reviews-slider"
    );
    const reviewsTextArr = googleReviewsSwiper.querySelectorAll(
        ".swiper-slide .review-text"
    );
    const reviewsMoreArr = googleReviewsSwiper.querySelectorAll(
        ".swiper-slide .review-text-more"
    );
    const maxLineLength = 6;
    let textHeight;
    /*if (window.innerWidth >= 1025) {
      textHeight = (18 * 2.1) * maxLineLength;
    } else {
      textHeight = (16 * 2.1) * maxLineLength;
    }*/

    if (window.innerWidth >= 1025) {
      textHeight = 132;
    } else {
      textHeight = 132;
    }

    reviewsTextArr.forEach((text, index) => {
      if (text.offsetHeight <= Math.round(textHeight)) {
        reviewsMoreArr[index].style.display = "none";
      } else {
        text.classList.add('line-clamp-6');
      }
    });

    reviewsMoreArr.forEach((elem, i) => {
      elem.addEventListener("click", () => {
        if (!reviewsTextArr[i].classList.contains("active")) {
          reviewsTextArr[i].classList.add("active");
          elem.textContent = "Close";
        } else {
          reviewsTextArr[i].classList.remove("active");
          elem.textContent = "Read More";
        }
      });
    });

    const stars = googleReviewsSwiper.querySelectorAll('.star');
    for (let i = 0; i < stars.length; i++) {
      if(stars[i].classList.contains('inactive')) {
        stars[i].parentElement.classList.add('inactive');
      }
    }

    googleReviews.style.opacity = 1;
  }
  // textMore();

  setTimeout(textMore, 1200);
}