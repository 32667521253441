import Swiper, { Autoplay, Navigation } from 'swiper';
import { followingSidebar } from '../utils/sidebar.utils.js';

const sidebar = document.querySelector('#sidebar');
const contentSticky = document.querySelector('#content-sticky');
const filtersBtn = document.querySelector('.filters-btn');
const contentResult = document.querySelector('.job-search-section__result');

if (sidebar && window.innerWidth >= 640) {
  followingSidebar({ sidebar, contentSticky });
}

if (filtersBtn) {
  filtersBtn.addEventListener('click', () => {
    filtersBtn.classList.toggle('active');
    contentSticky.classList.toggle('active');
    contentResult.classList.toggle('active');
  });

  contentResult.addEventListener('click', () => {
    if (contentResult.classList.contains('active')) {
      filtersBtn.classList.remove('active');
      contentSticky.classList.remove('active');
      contentResult.classList.remove('active');
    }
  });
}

window.jobResultFunc = function jobResultFunc() {
  let jobResultSwiperEl = document.querySelector('.job-result-content-swiper-wr .swiper');
  let jobResultSwiper = new Swiper(jobResultSwiperEl, {
    modules: [Autoplay, Navigation],
    observer: true, //needs to calculate swiper size
    observeParents: true, //needs to calculate swiper size
    spaceBetween: 24,
    slidesPerView: 1,
    threshold: 10,
    allowTouchMove: false,
    autoHeight: true,
  });

  let jobCardsSwiperEl = document.querySelector('.jobs-cards-swiper-wr .swiper');
  let jobCardsSwiper = new Swiper(jobCardsSwiperEl, {
    modules: [Autoplay, Navigation],
    observer: true,
    observeParents: true,
    spaceBetween: 20,
    slidesPerView: 1,
    threshold: 10,
  });

  const jobsCardListArr = document.querySelectorAll('.jobs-result-section__list .job-second-card');
  const jobsCardListSecondArr = document.querySelectorAll('.jobs-result-section__list-slider .job-second-card');
  jobsCardListArr.forEach((jobCard, i) => {
    jobCard.addEventListener('click', () => {
      for (let j = 0; j < jobsCardListArr.length; j++) {
        jobsCardListArr[j].classList.remove('active');
        jobsCardListSecondArr[j].classList.remove('active');
      }
      jobCard.classList.add('active');
      jobsCardListArr[i].classList.add('active');
      jobResultSwiper.slideTo(i);
    });
  })

  jobsCardListSecondArr.forEach((jobCard, i) => {
    jobCard.addEventListener('click', () => {
      for (let j = 0; j < jobsCardListSecondArr.length; j++) {
        jobsCardListSecondArr[j].classList.remove('active');
        jobsCardListArr[j].classList.remove('active');
      }
      jobCard.classList.add('active');
      jobsCardListSecondArr[i].classList.add('active');
      jobResultSwiper.slideTo(i);
    });
  });
}
jobResultFunc();
