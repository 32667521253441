import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('stats-section__slider')[0]) {
  const statsSlidersArr = document.querySelectorAll('.stats-section__slider');
  statsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    let breakpoints = {
      451: {
        spaceBetween: 24,
        slidesPerView: 1.5,
      },
      651: {
        spaceBetween: 30,
        slidesPerView: 2,
      },
      901: {
        spaceBetween: 30,
        slidesPerView: 2.5,
      },
      1025: {
        spaceBetween: 40,
        slidesPerView: 2,
      },
      1200: {
        spaceBetween: 61,
        slidesPerView: 2,
      },
    };

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    } else {
      sliderEl.classList.add('not-looped');
      breakpoints = {
        451: {
          spaceBetween: 24,
          slidesPerView: 1.8,
        },
        651: {
          spaceBetween: 30,
          slidesPerView: 2.5,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      };

      if (window.innerWidth <= 641 && slides.length >= 2) {
        loop = true;
        loopedSlides = slides.length * 4;
      }
    }

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const statsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 24,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 1600,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      /*navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: breakpoints,
    });

    swiperObserver(statsSwiper);
  });
}
