const ourSolutionsSection = document.getElementsByClassName(
  'our-solutions-section'
)[0];
if (ourSolutionsSection) {
  const ourSolutionsListArr = ourSolutionsSection.querySelectorAll(
    '.our-solutions-section__item-list'
  );
  const ourSolutionsButtons = ourSolutionsSection.querySelector(
    '.our-solutions-section__bottom-buttons'
  );

  ourSolutionsListArr.forEach((listEl, idx) => {

    const ulElements = ourSolutionsSection.querySelectorAll('ul');

    ulElements.forEach(list => {

      ulElements.forEach(ul => {
        const liElements = ul.querySelectorAll('li');

        liElements.forEach(li => {
          const span = document.createElement('span');
          span.textContent = li.textContent.trim();
          li.innerHTML = '';

          const icon = document.createElement('i');
          icon.classList.add('icon-check');

          li.appendChild(icon);
          li.appendChild(span);
        });
      });

      const itemsArr = list.querySelectorAll('li');
      const listHeight = list.clientHeight;
      /*if (itemsArr.length > 4) {
        list.style.maxHeight = `${listHeight / 2}px`;
      }*/

      if (itemsArr.length <= 4) {
        list.classList.add('full-size');
      }

      console.log(idx, ourSolutionsListArr.length);

      if (idx === ourSolutionsListArr.length - 1 && itemsArr.length <= 2) {
        ourSolutionsButtons.classList.add('margin-list');
      }
    })
  });
}
