import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('how-we-section__slider')[0]) {
  const howWeSlidersArr = document.querySelectorAll('.how-we-section__slider');
  howWeSlidersArr.forEach((sliderEl, sliderIdx) => {
    const prevEl = document.querySelector('.how-we-slider-buttons .slider-btn.prev'),
        nextEl = document.querySelector('.how-we-slider-buttons .slider-btn.next');
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let initialLoop = false;
    let initialLoopedSlides = false;

    if (slides.length >= 4) {
      initialLoop = true;
      initialLoopedSlides = slides.length * 4;
    }

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const howWeSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 54,
      threshold: 10,
      speed: 600,
      centeredSlides: true,
      loop: false, // Без loop
      autoplay: false,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        552: {
          spaceBetween: 54,
          slidesPerView: 1,
          centeredSlides: false,
        },
      },
    });

    function enableLoop() {
      if (!howWeSwiper.params.loop) {
        howWeSwiper.params.loop = initialLoop;
        howWeSwiper.params.loopedSlides = initialLoopedSlides;
        howWeSwiper.loopDestroy();
        howWeSwiper.loopCreate();
        howWeSwiper.update();
      }
    }

    /*prevEl.addEventListener('click', enableLoop);
    nextEl.addEventListener('click', enableLoop);
    sliderEl.addEventListener('touchstart', enableLoop);*/

    howWeSwiper.on('slideChangeTransitionEnd', enableLoop);

    /*swiperObserver(howWeSwiper);*/
  });
}
