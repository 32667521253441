import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('gallery-section__slider')[0]) {
  const gallerySlidersArr = document.querySelectorAll(
    '.gallery-section__slider'
  );
  gallerySlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const gallerySwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      /*navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 40,
          slidesPerView: 3,
        },
      },
    });

    /*swiperObserver(gallerySwiper);*/

    setTimeout(function () {
      initTilt();
    }, 50);
  });
}
