import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utills.js';

import './components/header.component.js';
import './components/animations.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/about.component.js';
import './components/our-solutions.component.js';
import './components/jobs-search.component.js';
import './components/feauteres.component.js';
import './components/map.component.js';
import './components/google.component.js';

/*Sliders*/
import './components/logos.component.js';
import './components/stats.component.js';
import './components/areas.component.js';
import './components/gallery.component.js';
import './components/roles.component.js';
import './components/news.component.js';
/*import './components/specialism.component.js';
import './components/credentials.component.js';*/
import './components/testimonials.component.js';
import './components/services.component.js';
import './components/history.component.js';
import './components/teams.component.js';
import './components/offer-services.component.js';
import './components/how-we.component.js';
import './components/case-studies.component.js';
/*End Sliders*/

import './libs/countUp.lib.js';
