import Swiper, { Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from 'simplebar';

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll(
    '.testimonials-section__slider'
  );
  const testimonialsSliderButtonsArr = document.querySelectorAll(
    '.testimonials-section__slider-buttons'
  );
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 3;
    }*/
    const pagination = sliderEl.querySelector('.swiper-pagination');

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      pagination: {
        el: pagination,
        clickable: true,

      },
      /*navigation: {
        prevEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.prev'),
        nextEl: testimonialsSliderButtonsArr[sliderIdx].querySelector('.slider-btn.next'),
      },*/
    });

    setTimeout(function () {
      const testimonialsItemsArr = sliderEl.querySelectorAll(
        '.testimonials-section__item-text'
      );
      console.log(testimonialsItemsArr.length);
      for (let i = 0; i < testimonialsItemsArr.length; i++) {
        new SimpleBar(testimonialsItemsArr[i]);
      }
    }, 50);
  });
}
