// MAP

const isTab = window.matchMedia("(max-width: 1025px)");
const isMob = window.matchMedia("(max-width: 551px)");

const mapSection = document.querySelector(".map");

if (mapSection) {
  // MAP CHART
  const setChart = (countryChartEl) => {
    const chartWrap = countryChartEl.querySelector(".map__chart");
    const chartCanvasWrap = countryChartEl.querySelector(
        ".map__chart-canvas-wrap"
    );
    const chartChartTable = countryChartEl.querySelector(".map__chart-table");
    const chartTableHandsOn = countryChartEl.querySelector(
        ".map__chart-table-item-list[data-type='hands-on']"
    );
    const chartTableAreas = countryChartEl.querySelector(
        ".map__chart-table-item-list[data-type='areas']"
    );
    const chartTableManagement = countryChartEl.querySelector(
        ".map__chart-table-item-list[data-type='management']"
    );

    const chartTableHandsOnItems = chartTableHandsOn.querySelectorAll(
        ".map__chart-table-item"
    );
    const chartTableAreasItems = chartTableAreas ? chartTableAreas.querySelectorAll(".map__chart-table-item") : [];
    const chartTableManagementItems = chartTableManagement ? chartTableManagement.querySelectorAll(".map__chart-table-item") : [];
    // set only number for data-percentage
    [
      ...chartTableHandsOnItems,
      ...chartTableAreasItems,
      ...chartTableManagementItems,
    ].forEach((item) => {
      item.setAttribute(
          "data-percentage",
          parseFloat(item.getAttribute("data-percentage").replace(/^\D+/g, "")).toString()
      );
    });
    const percDOM = countryChartEl.querySelector(".map__chart-perc");
    const percTextDOM = countryChartEl.querySelector(".map__chart-perc-text");

    countryChartEl.addEventListener("click", (e) => {
      if (
          matchMedia("(max-width: 768px)").matches &&
          e.target === e.currentTarget
      ) {
        activeChart?.classList.remove("is-open");

        scrollLock.enablePageScroll();
      }
    });

    const BACKGROUND_COLORS = [
      "rgba(6, 23, 46, 1)",
      "rgba(239, 27, 46, 1)",
      "rgba(115, 27, 239, 1)",
      "rgba(27, 239, 186, 1)",
      "rgba(226, 118, 0, 1)",
      "rgba(182, 67, 194, 1)",
      "rgba(226, 171, 28, 1)",
    ];
    const BACKGROUND_HOVER_COLORS = [
      "rgba(6, 23, 46, 0.7)",
      "rgba(239, 27, 46, 0.7)",
      "rgba(115, 27, 239, 0.7)",
      "rgba(27, 239, 186, 0.7)",
      "rgba(226, 118, 0, 0.7)",
      "rgba(182, 67, 194, 0.7)",
      "rgba(226, 171, 28, 0.7)",
    ];
    const BACKGROUND_ACTIVE_COLORS = [
      "rgba(6, 23, 46, 0.5)",
      "rgba(239, 27, 46, 0.5)",
      "rgba(115, 27, 239, 0.5)",
      "rgba(27, 239, 186, 0.5)",
      "rgba(226, 118, 0, 0.5)",
      "rgba(182, 67, 194, 0.5)",
      "rgba(226, 171, 28, 0.5)",
    ];

    const handleTabChange = (type) => {
      activeTable.classList.remove("active");

      activeTable = getTable(type);

      activeTable.classList.add("active");

      chart.data = getData(type);

      if (chart.data.labels.length) {
        chart.update();
        chartCanvasWrap.style.display = "flex";
        chartChartTable.style.display = "block";
      } else {
        chartCanvasWrap.style.display = "none";
        chartChartTable.style.display = "none";
      }

      if(type === "areas") {
        chartCanvasWrap.classList.add("not-chart-type");
        chartChartTable.classList.add("not-chart-type");
      } else {
        chartCanvasWrap.classList.remove("not-chart-type");
        chartChartTable.classList.remove("not-chart-type");
      }

      chart.update();

      [...countryChartEl.querySelectorAll("[data-simplebar]")].forEach((el) => {
        const simpleBar = SimpleBar.instances.get(el);

        simpleBar?.recalculate();
      });


    };

    const getData = (type) => {
      switch (type) {
        case "hands-on":
          return dataHandsOn;
        case "areas":
          return dataAreas;
        case "management":
          return dataManagement;
        default:
          break;
      }
    };
    const getTable = (type) => {
      switch (type) {
        case "hands-on":
          return chartTableHandsOn;
        case "areas":
          return chartTableAreas;
        case "management":
          return chartTableManagement;
        default:
          break;
      }
    };

    const tabBtns = countryChartEl.querySelectorAll(".map__chart-tab");

    let activeTab = tabBtns[0];
    let activeTable = getTable(tabBtns[0].dataset.type);

    // activeTab.classList.add("active");

    const dataHandsOn = [...chartTableHandsOnItems].reduce(
        (acc, item) => {
          const { title, percentage } = item.dataset;
          acc.labels.push(title);
          acc.datasets[0].data.push(percentage);
          return acc;
        },
        {
          labels: [],
          datasets: [
            {
              activeData: null,
              data: [],
              backgroundColor: [...BACKGROUND_COLORS],
              hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
              borderColor: "rgb(255, 255, 255)",
              hoverBorderColor: "rgb(255, 255, 255)",
              borderWidth: 2,
            },
          ],
        }
    );

    const dataAreas = [...chartTableAreasItems].reduce(
        (acc, item) => {
          const { title, percentage } = item.dataset;
          acc.labels.push(title);
          acc.datasets[0].data.push(percentage);
          return acc;
        },
        {
          labels: [],
          datasets: [
            {
              activeData: null,
              data: [],
              backgroundColor: [...BACKGROUND_COLORS],
              hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
              borderColor: "rgb(255, 255, 255)",
              hoverBorderColor: "rgb(255, 255, 255)",
              borderWidth: 2,
            },
          ],
        }
    );

    const dataManagement = [...chartTableManagementItems].reduce(
        (acc, item) => {
          const { title, percentage } = item.dataset;
          acc.labels.push(title);
          acc.datasets[0].data.push(percentage);
          return acc;
        },
        {
          labels: [],
          datasets: [
            {
              activeData: null,
              data: [],
              backgroundColor: [...BACKGROUND_COLORS],
              hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
              borderColor: "rgb(255, 255, 255)",
              hoverBorderColor: "rgb(255, 255, 255)",
              borderWidth: 2,
            },
          ],
        }
    );

    const width = chartWrap.clientWidth;
    const height = chartWrap.clientHeight;

    const activeDataRender = {
      id: "activeDataRender",
      beforeInit(chart) {
        if (!chart.config.data.datasets[0].activeData) {
          chart.config.data.datasets[0].backgroundColor[0] =
              BACKGROUND_ACTIVE_COLORS[0];
          chart.config.data.datasets[0].hoverBackgroundColor[0] =
              BACKGROUND_ACTIVE_COLORS[0];
        }
      },
      beforeUpdate(chart) {
        if (!chart.config.data.datasets[0].activeData) {
          chart.config.data.datasets[0].backgroundColor[0] =
              BACKGROUND_ACTIVE_COLORS[0];
          chart.config.data.datasets[0].hoverBackgroundColor[0] =
              BACKGROUND_ACTIVE_COLORS[0];
        }
      },

      afterDraw(chart, args, options) {
        // const {
        //   ctx,
        //   // chartArea: { width, height },
        // } = chart;
        const ctx = chart.canvas.getContext("2d");
        ctx.save();

        if (!chart.config.data.datasets[0].activeData) {
          const procentage = chart.config.data.datasets[0].data[0];
          const specialisms = chart.config.data.labels[0];

          chart.config.data.datasets[0].activeData = {
            procentage,
            specialisms,
            idx: 0,
          };
        }

        const { procentage, specialisms, idx } =
            chart.config.data.datasets[0].activeData;

        // chart.config.data.datasets[0].backgroundColor[idx] =
        // 	BACKGROUND_ACTIVE_COLORS[idx];
        // chart.config.data.datasets[0].hoverBackgroundColor[idx] =
        // 	BACKGROUND_ACTIVE_COLORS[idx];

        if (!window.matchMedia("(max-width: 1281px)").matches) {
          const findElementWithPool = (specialisms, percentage) => {
            const selector = `[data-title="${specialisms}"][data-percentage="${percentage}"]`;
            const element = document.querySelector(selector);

            if (element) {
              const dataPool = element.getAttribute("data-pool");
              return dataPool ? dataPool : "";
            } else {
              return "";
            }
          };

          const dataPoolValue = findElementWithPool(specialisms, procentage);

          ctx.font = "500 40px Effra";
          ctx.fillStyle = "rgb(6, 23, 46)";
          ctx.textAlign = "center";
          chart.ctx.textBaseline = "middle";
          let metrics = ctx.measureText(procentage);
          let actualHeight =
              metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
          ctx.fillText(
              dataPoolValue,
              width / 2,
              height / 2 - actualHeight / 2,
              136
          );
          /*ctx.fillText(
              procentage + "%",
              width / 2,
              height / 2 - actualHeight / 2,
              136
          );*/


          ctx.font = "500 13px Archivo";
          ctx.fillStyle = "rgb(51, 66, 79)";
          ctx.textAlign = "center";
          chart.ctx.textBaseline = "middle";
          const wrapText = function (ctx, text, x, y, maxWidth, lineHeight) {
            let words = text.split(" ");
            let line = "";
            let testLine = "";
            let lineArray = [];

            for (var n = 0; n < words.length; n++) {
              testLine += `${words[n]} `;
              let metrics = ctx.measureText(testLine);
              let testWidth = metrics.width;

              if (testWidth > maxWidth && n > 0) {
                lineArray.push([line, x, y]);

                y += lineHeight;

                line = `${words[n]} `;
                testLine = `${words[n]} `;
              } else {
                line += `${words[n]} `;
              }

              if (n === words.length - 1) {
                lineArray.push([line, x, y]);
              }
            }
            // Return the line array
            return lineArray;
          };
          const text = wrapText(
              ctx,
              specialisms.replace(/\s{2,}/g, " "),
              width / 2,
              height / 2 + actualHeight * 2,
              100,
              14
          );

          metrics = ctx.measureText(...text);
          actualHeight =
              metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

          // ctx.fillText(text, width / 2, height / 2 + actualHeight * 2, 136);
          text.forEach((item) => {
            ctx.fillText(item[0], item[1], item[2] - 12 * text.length);
          });
        } else {
          const { procentage, specialisms } =
              chart.config.data.datasets[0].activeData;

          // percDOM.textContent = procentage + "%";
          percDOM.style.display = 'none';
          percTextDOM.textContent = specialisms;
        }
      },
    };

    const config = {
      type: "doughnut",
      data: getData(activeTab.dataset.type),
      plugins: [activeDataRender],
      options: {
        spacing: 7,
        cutoutPercentage: 75,
        // borderWidth: 22,
        responsive: true,
        borderColor: "#ffffff",
        onHover({ target: canvas }, elements) {
          // cursor pointer for elements hover
          canvas.style.cursor = elements.length ? "pointer" : "default";
        },
        onClick({ target }, elements) {
          // const {
          //   ctx,
          //   // chartArea: { width, height },
          // } = chart;
          const ctx = target.getContext("2d");

          ctx.save();

          if (elements.length) {
            const procentage =
                chart.config.data.datasets[0].data[elements[0]._index];
            const specialisms = chart.config.data.labels[elements[0]._index];

            chart.config.data.datasets[0].backgroundColor =
                chart.config.data.datasets[0].backgroundColor.map(
                    (color, idx) => {
                      return BACKGROUND_COLORS[idx];
                    }
                );

            chart.config.data.datasets[0].hoverBackgroundColor =
                chart.config.data.datasets[0].hoverBackgroundColor.map(
                    (color, idx) => {
                      return BACKGROUND_HOVER_COLORS[idx];
                    }
                );

            chart.config.data.datasets[0].backgroundColor[elements[0]._index] =
                BACKGROUND_ACTIVE_COLORS[elements[0]._index];
            chart.config.data.datasets[0].hoverBackgroundColor[
                elements[0]._index
                ] = BACKGROUND_ACTIVE_COLORS[elements[0]._index];

            chart.config.data.datasets[0].activeData = {
              procentage,
              specialisms,
              idx: elements[0]._index,
            };



            if (!window.matchMedia("(max-width: 1281px)").matches) {
              const findElementWithPool = (specialisms, percentage) => {
                const selector = `[data-title="${specialisms}"][data-percentage="${percentage}"]`;
                const element = document.querySelector(selector);

                if (element) {
                  const dataPool = element.getAttribute("data-pool");
                  return dataPool ? dataPool : "";
                } else {
                  return "";
                }
              };

              const dataPoolValue = findElementWithPool(specialisms, procentage);
              ctx.font = "500 36px Effra";
              ctx.fillStyle = "rgb(6, 23, 46)";
              ctx.textAlign = "center";
              chart.ctx.textBaseline = "middle";
              let metrics = ctx.measureText(
                  chart.config.data.datasets[0].activeData.procentage
              );
              let actualHeight =
                  metrics.actualBoundingBoxAscent +
                  metrics.actualBoundingBoxDescent;
              ctx.fillText(
                  dataPoolValue,
                  width / 2,
                  height / 2 - actualHeight / 2,
                  136
              );

              /*ctx.fillText(
                  chart.config.data.datasets[0].activeData.procentage + "%",
                  width / 2,
                  height / 2 - actualHeight / 2,
                  136
              );*/

              // ctx.font = "500 14px Effra";
              // ctx.fillStyle = "rgb(34, 40, 55)";
              // ctx.textAlign = "center";
              // chart.ctx.textBaseline = "middle";
              // // metrics = ctx.measureText(
              // //   chart.config.data.datasets[0].activeData.specialisms
              // // );
              // // actualHeight =
              // //   metrics.actualBoundingBoxAscent +
              // //   metrics.actualBoundingBoxDescent;

              // // ctx.fillText(
              // //   chart.config.data.datasets[0].activeData.specialisms,
              // //   width / 2,
              // //   height / 2 + actualHeight * 2,
              // //   136
              // // );
            } else {
              // percDOM.textContent = procentage + "%";
              percDOM.style.display = 'none';
              percTextDOM.textContent = specialisms;
            }
            chart.update();
          }
        },
        // events: ["click"],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {},
      },
    };

    const chart = new Chart(countryChartEl.querySelector(".chart"), config);

    handleTabChange(activeTab.dataset.type);

    tabBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (activeTab === btn) return;

        handleTabChange(btn.dataset.type);

        activeTab.classList.remove("active");

        activeTab = btn;

        activeTab.classList.add("active");
      });
    });
  };

  const countryCharts = document.querySelectorAll(".map__chart-wrap");

  countryCharts.forEach(setChart);

  let activeChart = null;

  const getChart = (country) => {
    return [...countryCharts].find((item) => item.dataset.country === country);
  };

  // Offices info
  const officesTitle = document.querySelector(".map__offices-title");
  const officesText = document.querySelector(".map__offices-offices-text");

  // MAP
  const setMap = () => {
    try {
      const map = mapSection
          .querySelector(".map__wrap")
          .contentDocument.querySelector("svg");

      let xPos = window.innerWidth > 640 ? 100 : 1;

      const moveUkBtn = document.querySelector("#to-uk");
      const moveUsaBtn = document.querySelector("#to-usa");
      const moveEuBtn = document.querySelector("#to-eu");
      const openPercBtn = document.querySelector(".map__open-perc-btn");
      const closePercBtns = document.querySelectorAll(".map__chart-close-btn");

      const euCountries = [
        "#BE",
        "#PT",
        "#PL",
        "#NL",
        "#BG",
        "#IT",
        "#DE",
        "#FR",
        "#ES",
        "#HR",
        "#RO",
        "#SI",
        "#SK",
        "#CZ",
        "#AT",
        "#CH",
        "#BA",
        "#HU",
        "#RS",
        "#ME",
        "#XK",
        "#AL",
        "#MK",
        "#TR",
        "#GR",
        "#UK",
        "#MD",
        "#DK",
        "#SE",
        "#NO",
        "#FI",
        "#LT",
        "#LV",
        "#EE",
        "#BY",
      ];

      const baseViewBox = map.getAttribute("viewBox");
      const defaultCountries = [
        {
          btn: moveUkBtn,
          list: ["#GB", "#IR"],
        },
        {
          btn: moveUsaBtn,
          list: ["#CA", "#US"],
        },
        {
          btn: moveEuBtn,
          list: euCountries,
        },
      ];

      const disableAllBtn = () => {
        [moveUkBtn, moveUsaBtn, moveEuBtn].forEach((btn) =>
            btn.setAttribute("disabled", true)
        );
      };

      const enableAllBtn = () => {
        [moveUkBtn, moveUsaBtn, moveEuBtn].forEach((btn) =>
            btn.removeAttribute("disabled")
        );
      };

      map.addEventListener("click", ({ target }) => {
        // console.log(target);
        if (activeCountries.length && !activeCountries.includes(target)) {
          showAll();

          activeChart?.classList.remove("active");
          activeChart = null;
        }
      });

      const svgGrayStop1 = map.querySelector("#grayStopColor1");
      const svgGrayStop2 = map.querySelector("#grayStopColor2");
      const svgGradientStop1 = map.querySelector("#grStopColor1");
      const svgGradientStop2 = map.querySelector("#grStopColor2");

      const MIN_SIZE_DESKTOP = 200;
      const MIN_SIZE_TAB = 250;
      const MIN_SIZE_MOBILE = 160;

      let activeCountries = [];
      let activeLocationBtn = null;

      const moveTo = (selectors) => {
        disableAllBtn();
        activeCountries.forEach((country) => {
          country.style.fill = "url(#magenta-gr-2)";
          svgGrayStop1.attributes["stop-color"].value = "#F7F7F7";
          svgGrayStop2.attributes["stop-color"].value = "#F7F7F7";
          svgGradientStop1.attributes["stop-color"].value = "#F35A36";
          svgGradientStop2.attributes["stop-color"].value = "#F35A36";
        });

        const countries = selectors.map((selector) => {
          return map.querySelector(selector);
        });

        const svgBox = countries[0].getBBox();

        countries.forEach((country) => {
          const { x, y, width, height } = country.getBBox();

          if (x < svgBox.x) {
            svgBox.width += svgBox.x - x;
            svgBox.x = x;
          }
          if (y < svgBox.y) {
            svgBox.height += svgBox.y - y;
            svgBox.y = y;
          }
          if (x + width > svgBox.x + svgBox.width)
            svgBox.width = x + width - svgBox.x;
          if (y + height > svgBox.y + svgBox.height)
            svgBox.height = y + height - svgBox.y;

          if (activeCountries.length) country.style.fill = "url(#magenta-gr)";
        });

        let minSize = isMob.matches
            ? MIN_SIZE_MOBILE
            : isTab.matches
                ? MIN_SIZE_TAB
                : MIN_SIZE_DESKTOP;

        if (svgBox.width < minSize) {
          svgBox.x -= (minSize - svgBox.width) / 2;
          svgBox.width = minSize;
        } else {
          // Additing X gap for big countries
          svgBox.x -= (svgBox.width * 0.3) / 2;
          svgBox.width += svgBox.width * 0.3;
        }
        if (svgBox.height < minSize) {
          svgBox.y -= (minSize - svgBox.height) / 2;
          svgBox.height = minSize;
        } else {
          // Additing Y gap for big countries
          svgBox.y -= (svgBox.height * 0.3) / 2;
          svgBox.height += svgBox.height * 0.3;
        }

        console.log(svgBox);

        // if (svgBox.y + svgBox.height > map.clientHeight) {
        // 	const diff = svgBox.y + svgBox.height - map.clientHeight;

        // 	svgBox.y -= diff;
        // }
        if (activeCountries.length) {
          gsap.to(svgGrayStop1, {
            duration: 1,
            attr: {
              "stop-color": "#F35A36",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGrayStop2, {
            duration: 1,
            attr: {
              "stop-color": "#F35A36",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGradientStop1, {
            duration: 1,
            attr: {
              "stop-color": "#F7F7F7",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGradientStop2, {
            duration: 1,
            attr: {
              "stop-color": "#F7F7F7",
            },
            ease: "power3.inOut",
          });
          gsap.to(map, {
            duration: 1,
            attr: {
              viewBox: [svgBox.x + xPos, svgBox.y, svgBox.width, svgBox.height].join(
                  " "
              ),
            },
            ease: "power3.inOut",
            onComplete() {
              activeCountries.forEach((country) => {
                country.style.fill = "";
              });
              activeCountries = countries;
              enableAllBtn();
            },
          });
        } else {
          disableAllBtn();
          gsap.to(map, {
            duration: 1,
            attr: {
              viewBox: [svgBox.x + xPos, svgBox.y, svgBox.width, svgBox.height].join(
                  " "
              ),
            },
            ease: "power3.inOut",
            onComplete() {
              activeCountries.forEach((country) => {
                country.style.fill = "";
              });

              svgGrayStop1.attributes["stop-color"].value = "#F35A36";
              svgGrayStop2.attributes["stop-color"].value = "#F35A36";

              activeCountries = countries;
              activeCountries.forEach((country) => {
                country.style.fill = "url(#magenta-gr)";
              });

              gsap.to(svgGradientStop1, {
                duration: 1,
                attr: {
                  "stop-color": "#F7F7F7",
                },
                ease: "power3.inOut",
              });
              gsap.to(svgGradientStop2, {
                duration: 1,
                attr: {
                  "stop-color": "#F7F7F7",
                },
                ease: "power3.inOut",
              });
              enableAllBtn();
            },
          });
        }
      };

      const setPercBtnDisebling = () => {
        openPercBtn.style.opacity = activeLocationBtn ? "1" : "0";
        openPercBtn.style.pointerEvents = activeLocationBtn ? "auto" : "none";
      };

      const changeBtnState = ({ target }) => {
        activeLocationBtn?.classList.remove("active");
        // activeLocationBtn?.removeAttribute("disabled");

        target.classList.add("active");
        // target.setAttribute("disabled", true);

        officesTitle.textContent = target.dataset.infoTitle;
        officesText.textContent = target.dataset.infoText;

        activeLocationBtn = target;

        setPercBtnDisebling();
      };

      setPercBtnDisebling();

      const setDefaultCountries = (list, addListener = true, ignored = []) => {
        // const countries = selectors.map((selector) => {
        //   return map.querySelector(selector);
        // });
        list.forEach((tabObj) => {
          tabObj.list.forEach((country) => {
            const countryPath = map.querySelector(country);

            if (!ignored.includes(country)) {
              countryPath.style.fill = "url(#magenta-gr-2)";
              countryPath.style.cursor = "pointer";
            }

            if (addListener) {
              countryPath.addEventListener("click", () => {
                // moveTo(countryList);
                if (!activeCountries.length) tabObj.btn.click();
              });
            }
          });
        });
      };

      setDefaultCountries(defaultCountries);

      const showAll = () => {
        disableAllBtn();

        gsap.to(map, {
          duration: 1,
          attr: {
            viewBox: baseViewBox,
          },
          ease: "power3.inOut",
          onComplete() {
            // activeCountries.forEach((country) => {
            //   country.style.fill = "";
            // });

            svgGrayStop1.attributes["stop-color"].value = "#F35A36";
            svgGrayStop2.attributes["stop-color"].value = "#F35A36";
            svgGradientStop1.attributes["stop-color"].value = "#F7F7F7";
            svgGradientStop2.attributes["stop-color"].value = "#F7F7F7";

            activeCountries.forEach((country) => {
              country.style.fill = "url(#magenta-gr)";
            });
            const activeCountriesIds = activeCountries.map(
                (item) => "#" + item.id
            );
            setDefaultCountries(defaultCountries, false, activeCountriesIds);

            // svgGradientStop1.attributes["stop-color"].value = "#B919AA";
            // svgGradientStop2.attributes["stop-color"].value = "#6a208a";

            gsap.to(svgGradientStop1, {
              duration: 1,
              attr: {
                "stop-color": "#F35A36",
              },
              ease: "power3.inOut",
            });
            gsap.to(svgGradientStop2, {
              duration: 1,
              attr: {
                "stop-color": "#F35A36",
              },
              ease: "power3.inOut",
              onComplete() {
                activeCountries.forEach((country) => {
                  country.style.fill = "url(#magenta-gr-2)";
                });

                activeCountries = [];
                activeLocationBtn?.classList.remove("active");
                activeLocationBtn = null;
                setPercBtnDisebling();
                enableAllBtn();
              },
            });
          },
        });
      };

      moveUkBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("uk-ireland");

        activeChart.classList.add("active");

        moveTo(["#GB", "#IR"]);
      });

      moveUsaBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("us-canada");

        activeChart.classList.add("active");

        moveTo(["#CA", "#US"]);
      });

      moveEuBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("eu");

        activeChart.classList.add("active");

        moveTo(euCountries);
      });

      openPercBtn.addEventListener("click", () => {
        activeChart?.classList.toggle("is-open");

        if (window.matchMedia("(max-width: 769px)").matches) {
          scrollLock.getScrollState()
              ? scrollLock.disablePageScroll(
                  document.querySelector(".navbar-nav .menu")
              )
              : scrollLock.enablePageScroll();
        }
      });

      closePercBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          activeChart?.classList.remove("is-open");

          scrollLock.enablePageScroll();
        });
      });
    } catch (e) {
      console.info(e);
      setTimeout(setMap, 500);
    }
  };
  const mapWrap = mapSection.querySelector(".map__wrap");

  function setMapIfReady() {
    const map = mapWrap.contentDocument?.querySelector("svg");
    if (map) {
      setMap();
    }
  }

  mapWrap.addEventListener("load", setMapIfReady);

  setMapIfReady();

  /*const mapWrap = mapSection.querySelector(".map__wrap");

  function trySetMap() {
    const map = mapWrap?.contentDocument?.querySelector("svg");
    if (map) {
      setMap();
    }
  }

  trySetMap();

  mapWrap.addEventListener("load", trySetMap);*/
}